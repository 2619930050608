/* You can add global styles to this file, and also import other style files */
@import 'variables';
@import 'placeholders';
@import 'ioh-theme';
@import 'fonts';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  background: $main-background-color;
}
.body-overflow {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}
.mat-tab-link {
  min-width: 130px !important;
}
.acn-consent-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #333333;
  opacity: .5;
  z-index: 9999;
  color: #212529;
}
.acn-consent-dialog {
  background-color: #ffffff;
  color: #212529;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 90%;
  max-width: 800px;
  max-height: 600px;
  z-index: 9999;
  padding: 0 0 45px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
  box-sizing: border-box;
  overflow: hidden;
  font-family: 'Graphik', sans-serif;
}
.acn-consent-pg {
  max-width: 600px !important;
}
.acn-consent-bdy-ei {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 500px;
  box-sizing: border-box;
  padding: 20px;
}
.acn-consent-body {
  margin-bottom: 20px;
}
.acn-consent-priv-more {
  background: #ffffff !important;
  border: 1px solid #333333 !important;
  color: #000000 !important;
  padding: 8px 15px !important;
  margin: 0 !important;
  line-height: 1.2em !important;
}
.acn-u-btn,
a.acn-u-btn:link,
a.acn-u-btn:visited {
  text-decoration: none !important;
  padding: 8px 15px;
  border: 1px solid #333333;
  color: #000000;
  background-color: #ffffff;
  border-radius: 5px;
  transition: all .2s ease-in-out;
  display: inline-block;
  font-size: .9em;
  cursor: pointer;
}
input.acn-u-btn.acn-consent-btn-blue.acn-consent-priv-continue {
  background: #2962ff !important;
  border: #2962ff !important;
  padding: 8px 15px !important;
  line-height: 1.3em !important;
  margin: 0 !important;
  min-width: 90px;
}
.acn-consent-btn {
  padding-top: 10px;
  text-align: right !important;
  padding-right: 40px;
}

.flexMarin .dropdown__label,
.flexMarin .look-up__label,
.flexMarin .input__label {
  height: 1px;
  color: #ffffff;
}
.flexMarinNormal .dropdown__label,
.flexMarinNormal .look-up__label,
.flexMarinNormal .input__label {
  height: auto !important;
  color: #333333 !important;
}
.recovery-entry-row .dropdown__label,
.recovery-entry-row .input__label {
  font-size: 12px !important;
  font-weight: bold !important;
}
.recovery-entry-row .mat-select-value-text{
  font-size: 12px!important;
}
.recovery-entry-row .dateRow .mat-form-field-infix{
  width: auto!important;
}
.recovery-entry-row input{
  font-size:12px!important;
}
.recovery-entry-row .error__message{
  font-size: 12px!important;
}
.pendListTable table tr td:nth-child(2){
  padding: 0 9px;
}
.pendListTable table tr td:nth-child(3){
  padding: 0 5px;
}
.pendListTable table tr td:nth-child(4){
  padding: 1px;
}
.pendListTable table tr td:nth-child(5){
  padding: 0 4px;
}
.pendListTable table tr td:nth-child(6){
  padding: 0 5px;
}
.pendListTable table tr td:nth-child(7){
  padding: 0 5px;
}
.pendListTable table tr td:nth-child(8){
  padding: 0 5px;
}
.pendListTable table tr td:nth-child(9){
  padding: 0 5px;
}
.pendListTable table tr td:nth-child(10){
  padding: 0 5px;
}
.pendListTable table tr td:nth-child(11){
  padding: 0 15px;
}
table th div button{
  text-align: left;
}
.mb-0{
  margin-bottom: 0px!important;
}
th.mat-mdc-header-cell:first-of-type, td.mat-mdc-cell:first-of-type, td.mat-footer-cell:first-of-type{
  padding-left: 24px!important;
}
.mat-tab-link-container{
  z-index: 0!important;
}
.generalDate .mat-form-field{
  display: inline-block!important;
}
.pendingList{
  margin: 3rem 0 1rem 1rem!important;
}
.selectBox{
  position: relative;
}
.multipleFilter .fiscalYear{
  position: absolute;
  left: 185px;
}
.all-engagements-filter{
  position: absolute;
  right: 32px;
  top: 40px;
  width: 80%;
}
.filter-title{
  position: absolute;
  left: 50px;
  top: 46px;
  font-size: 16px;
  font-weight: bold;
}
.closed-title{
  position: absolute;
  left: 44px;
  top: 46px;
  font-size: 16px;
  font-weight: bold;
}
.all-engagements table tr td:nth-child(1){
  min-width: 100px;
}
.all-engagements table tr td:nth-child(3){
  min-width: 110px;
}
.all-engagements table tr td:nth-child(4){
  min-width: 135px;
}
.all-engagements table tr td:nth-child(5){
  min-width: 120px;
}
.user-list table tr td:nth-child(7){
  min-width: 120px;
}
.all-engagements table th.mat-header-cell:last-of-type{
  padding-right: 0;
}
.metric_download_button span{
  margin-left: -12px;
}
.all-eng-card .mat-card-content, .pending-card .mat-card-content{
  display: block!important;
}
.pending-card .filter-wrap{
  position: relative!important;
  left: 0!important;
  top: 0!important;
}
.adminModal{
  overflow-y:hidden!important;
}
.hub-reminder .mat-radio-group, .event-reminder .mat-radio-group{
  margin-left: 12px;
}
.recoveryLoading{
  margin-top: 20px!important;
}
.all-engagements .table{
  position: inherit!important;
}
.mat-snack-bar-container{
  margin-top: 48px  !important;
}
.fc-daygrid-event {
  // background: #7500c0!important;
  border: none!important;
  cursor: pointer;
}
.fc-dayGridMonth-view td.fc-daygrid-day{
  height: 120px!important;
}
.fc.fc-theme-standard .fc-toolbar .fc-button:disabled{
  background: #0000001f!important;
  color: #00000042!important;
}
.fc.fc-theme-standard .fc-toolbar .fc-button{
  background: #7500c0!important;
  border:none!important;
}
.fc-theme-standard .fc-toolbar .fc-button{
  box-shadow: none!important;
}
.fc-event-title{
  text-overflow: ellipsis!important;
  cursor: pointer;
}
.calendarBox .mat-card{
  padding-bottom: 40px!important;
}
.surveyTerms .mat-checkbox-inner-container{
  margin-top: 3px!important;
}
.downloadFormRow .multipleFilter .filterRow{
  display: block!important;
}
.downloadFormRow .multipleFilter .filterRow .mat-form-field-wrapper{
  padding-bottom: 0!important;
}
.ioh-pagination .mat-form-field-flex{
  background: #ffffff!important;
  height: 40px!important;
}
.ioh-pagination .mat-form-field{
  width: 70px!important;
}
.ioh-pagination .mat-select-arrow-wrapper{
  display: table-cell;
  vertical-align: bottom!important;
}
.saveView-button span{
  margin-left: -4px;
}

.viewButtonRow .mat-spinner{
  position: absolute;
  left: 12px;
  top: 6px;
}
/*********** ---- Angular material 15 css hack ---- ***********/

/* Tabs */
.mat-mdc-tab-link{
  text-decoration: none;
}
.mat-mdc-tab-header{
  border-bottom: 1px solid rgba(0,0,0,.12);
}
.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label{
  color: rgba(0,0,0,.87);
}
a.mat-tab-link{
  opacity: .6;
}
/* ListBox */
.mdc-menu-surface.mat-mdc-select-panel{
  padding-top: 0!important;
  padding-bottom: 0!important;
}
/* Input */
.mat-form-field-appearance-outline .mat-mdc-text-field-wrapper{
  margin: 0.25em 0;
}
.mat-mdc-text-field-wrapper {
  position: relative;
  padding-bottom: 1.34375em;
  padding: 0 .99em 0 .99em!important;
}
.mat-mdc-form-field-hint {
  color: rgba(0, 0, 0, 0.6);
}
.mat-mdc-form-field-subscript-wrapper{
  font-size: 75%;
}
.mdc-notched-outline__leading{
  border: 1px solid rgba(0,0,0,.12);
  border-right: none;
}
.mdc-notched-outline__trailing{
  border: 1px solid rgba(0,0,0,.12);
  border-left: none;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading, .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch, .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing{
  border-color: #a5a4bf;
  opacity: .8;
  border-width: 2px;
}
.mat-mdc-form-field-infix{
  min-height: auto!important;
}
.dropdown__field .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix{
  padding-top: 12px!important;
  padding-bottom: 11px!important;
}
.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix{
  padding-top: 14px!important;
  padding-bottom: 14px!important;
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading, .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch, .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing{
  border-width: 2px;
  border-color: #c2255c!important;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing{
  border-color: rgba(0,0,0,0.12);
}
/* Datepicker */
.datepicker .mat-mdc-form-field{
  display: block!important;
}
.datepicker__icon{
  margin-top: .1rem!important;
}
/* Button */
.mat-mdc-outlined-button:not(:disabled){
  border-color: #7500c0!important;
}
/* Checkbox */
.noFeedbackCheckbox .mdc-checkbox{
  padding-top: 2px!important;
  padding-right: 5px!important;
}
/* Lookup */
.look-up .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix{
  padding: 0.85rem 0;
}
.look-up .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled){
  background-color: #737297!important;
}
.look-up .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label{
  padding-left: 3px;
  color: #ffffff;
}
.look-up .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__icon--trailing{
  opacity: 1;
  color: #ffffff;
}
// .look-up .mat-form-field-appearance-outline .mat-mdc-text-field-wrapper{
//   min-height: 40px!important;
//   line-height: 40px!important;
// }
/* Dropdown */
.dropdown .mat-mdc-text-field-wrapper{
  background: #ffffff;
}
/* Date Location */
.date-location .mat-mdc-header-cell{
  margin-right: 0!important;
}
/* Dialog   */
.mat-mdc-dialog-surface{
  padding: 24px 24px 0 24px
}
.mat-mdc-dialog-container .mdc-dialog__title{
  padding-left: 0;
  padding-right: 0;
}
.mat-mdc-dialog-container .mdc-dialog__content{
  padding-left: 0!important;
  padding-right: 0!important;
}
.modal__close__button button{
  background: none!important;
  border: none!important;
}
.downloadWrap label{
  display: block;
}
.download-row .dropdown{
  display: block!important;
}
.downloadWrap .mat-form-field{
  display: block!important;
}
.fc .fc-button-group {
  margin-left: 220px;
}
.fc .fc-toolbar-title {
  margin-left: -220px !important;
  font-size: 1.25em !important;
  font-weight: bold;
}
.fc-timegrid-event-harness {
  min-height: 30px;
}  
/*table*/
.tooltip-box{
 float: right;
}
.tooltip{
  // margin-left: 40%!important;
  height: 19px;
  margin-top: -27px;
}
.squareRgb{
  background-color:  rgb(190, 130, 255)!important;
  align-self: center;
  width: 12px!important;
  height: 13px!important;
}
 .squaree{
  background-color: #e6dcff!important;
  width: 12px!important;
  height: 13px!important;
  align-self: center;
}
 .squareb{
  background-color:  #b455aa!important;
  width: 12px!important;
  height: 13px!important;
  align-self: center;
}
.squaref{
  background-color: #f3f2f5!important;
  width: 12px!important;
  height: 13px!important;
  align-self: center;
  border:0.5px solid  #000000;
}
.tooltip_textVisit{
  width: 169px;
}
.tooltip_textVirtual{
  width: 54px;
}
.tooltip_textIn{
  width: 73px;
}
.tooltip_textHy{
  width: 55px;
}
.tooltip_textVi{
  width: 162px;
}
.tooltip-space{
  width: 25px;
}
.dropdown_space{
  float: right;
}
.dropdown__fieldTemp .mat-mdc-text-field-wrapper{
  position: absolute!important;
  margin-top: -0.05rem!important;
  height: 34px!important;
  width: 200px!important;
  align-items: center!important;
}
.multipleFilterTemp .mat-mdc-text-field-wrapper{
  margin-left: -22.7rem;
  margin-top: -0.03rem;
  position: absolute;
  height: 34px!important;
  width: 200px!important;
  align-items: center;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled){
  background: none!important;
}
.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay{
  opacity: 0!important;
}
--fc-list-event-hover-bg-color{
  background: none!important;
}
.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay{
  opacity: 0;
}
.table table th.mat-mdc-header-cell{
  background: #f4f4fc;
  color: #6767a0;
  text-transform: uppercase;
  font-family: Graphik Semibold,arial,sans-serif;
  vertical-align: text-top;
  padding-top: 0.5rem!important;
  font-size: 12px;
}
th.mat-mdc-header-cell{
  padding: 0!important;
}
.table .mat-mdc-row:hover{
  background-color: #f2f2fc!important;
  cursor: pointer;
}
.mat-sort-header-content{
  text-align: left!important;
}
.exportReport .mat-icon{
  margin-left: 6px!important;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing{
  border-color: none!important;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing{
  border-color: none!important;
}
//popup
.mat-mdc-dialog-surface{
  padding-bottom: 25px!important;
}
.waring-icon-alert{
  width: 30px!important;
  height: 30px!important;
}
.metric_download_button{
  max-width: 5%!important;
}
.modal__icon__container .mat-icon{
  width: 30px!important;
  height: 30px!important;
}
.team__delete, .item__button__delete{
  width: 30px!important;
  height: 30px!important;
}
.softlockExpirationDate, .completeConsultationDate{
  width: 250px!important;
}
//snackbar
.mdc-snackbar{
  margin-top: 55px!important;
  margin-right: 15px!important;
}
//download popup
.downloadTitle{
  margin-top: -18px!important;
  font-size: 20px!important;
}
.downloadWrap .mat-mdc-form-field{
  width: 100%!important;
}
.downloadWrap .rowButton{
  padding-top: 0!important;
}
.mat-mdc-radio-button .mdc-radio{
  padding: 0!important;
}
.radio-row .mat-mdc-radio-button{
  margin-right: 5px!important;
  margin-left: 10px!important;
}
//date location
.date-location mat-header-cell:first-of-type{
  padding-left: .25rem!important;
  flex: 0 0 4%!important;
  margin-left: -4px!important;
}
.date-location .mat-mdc-header-cell{
  padding: 0!important;
}
.date-location .mdc-data-table__row{
  min-height: 48px!important;
  height: auto!important;
}
.date-location .mdc-data-table__cell, .date-location .mdc-data-table__header-cell{
  padding: 0!important;
}
.date-location .mat-mdc-row{
  padding-top: 20px!important;
  border-bottom-color: rgba(0,0,0,.12);
  border-bottom-width: 1px;
  border-style: solid;
}
.date-location .mdc-data-table__cell, .date-location .mdc-data-table__header-cell{
  border-bottom-width: 0;
  border-bottom-style: none;
}
.date-location mat-header-cell.mat-column-clientArrival{
  margin-left: 15px!important;
}
.date-location mat-header-cell.mat-column-startTime{
  margin-left: 10px!important;
}
.date-location mat-header-cell.mat-column-endTime{
  margin-left: 10px!important;
}
.date-location mat-header-cell.mat-column-hours{
  margin-left: 15px!important;
}
.date-location mat-header-cell.mat-column-location{
  margin-left: 15px!important;
}
.date-location mat-header-cell.mat-column-comments{
  margin-left: 10px!important;
}
.tooltip-font .material-icons, .hasTooltipIcon .material-icons{
  margin-left: 3px!important;
  top: 8px!important;
}
.mdc-button:disabled{
  pointer-events: auto!important;
}
.look-up__list .mat-mdc-chip{
  margin: -0.35rem 0.35rem -0.35rem 0!important;
}
.mat-mdc-standard-chip.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--trailing{
  padding-left: 0!important;
  padding-right: 0!important;
}
.mat-mdc-standard-chip.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--primary{
  padding-left: 2px!important;
}
.mat-mdc-dialog-title{
  font: 500 20px/32px "Graphik Regular", arial, sans-serif;
  letter-spacing: normal;
  font-size: 20px!important;
}
.mat-mdc-dialog-container .mdc-dialog__content{
  color: rgba(0, 0, 0, 0.87)!important;
}
.mat-mdc-dialog-container .dropdown__label, .mat-mdc-dialog-container .input__label, .mat-mdc-dialog-container .look-up__label{
  margin-bottom: 3px!important;
}
.mdc-dialog__actions{
  padding: 0!important;
}
.mdc-evolution-chip__text-label{
  white-space: inherit!important;
}
.logisticsWrap{
  margin-left: -10px;
}
.look-up__chips .mat-mdc-chip-remove{
  top: 2px!important;
}
.missingField{
  line-height: 20px!important;
}
.mat-mdc-chip{
  line-height: 16px!important;
}
.filter-field .mdc-text-field{
  padding-left: 0!important;
  padding-right: 0!important;
}
// .container__form div{
//   margin-bottom: auto!important;
// }
.cdk-overlay-pane:has(.allEng){
  min-width: 120px!important;
  width: auto!important;
}
.mat-mdc-option{
  padding-right: 35px!important;
}
.mdc-menu-surface--open{
  overflow-x: hidden!important;
}
.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple:before{
  opacity: 0!important;
}
.mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple:before{
  opacity: 0!important;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal:after, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal:after{
  opacity: 0!important;
}
.modal__close__button{
  margin-bottom: 5px!important;
}
.surveyText{
  line-height: 20px;
}
.questionListBox{
  margin-top: 28px!important;
}
.mdc-tooltip__surface{
  white-space: pre!important;
  background-color: white!important;
  color: black!important;
  border: 1px solid black!important;
  max-width: 600px!important;
  border-radius: 0!important;
  padding-left: 8px!important;
  padding-right: 8px!important;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
  font-size: 12px!important;
  text-align: left!important;
}
.userViewList{
  margin-top: -60px!important;
  overflow-y: hidden!important;
  max-height: fit-content!important;
}
.cdk-overlay-pane:has(.chargeStatusSelect){
  width: auto!important;
}
.row-survery-Box, .agreeBox{
  margin-bottom: 0!important;
}
.recoveryCheckbook .mdc-form-field label{
  display: none!important;
}