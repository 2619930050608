@use 'sass:map';
@use '@angular/material' as mat;
@mixin team-input() {
  .team {
    .look-up {
      &__chips {
        margin: -.31rem .38rem;
      }
    }
  }

  .look-up {
    &__placeholder {
      &__hide {
        width: 0;
        flex: auto;
      }
    }
  }
}

@mixin stroked-primary-btn-theme($theme) {
  // $primary: map-get($theme, primary);
  $primary: mat.get-color-config($theme);

  $primary-palette: map.get($primary, 'primary');

  .mat-primary.mat-stroked-button:not([disabled]) {
    border-color: mat.get-color-from-palette($primary-palette, 500);

    &:focus {
      box-shadow: 0 0 .25rem .15rem rgba(mat.get-color-from-palette($primary-palette, 500), .3);
    }
  }
}

@mixin mat-flat-primary-btn-theme($theme) {
  $primary: map-get($theme, primary);
// $black: mat-contrast($primary, A100);
  .organisation__add.mat-flat-button.mat-primary[disabled] {
    color: rgba(0,0,0, .6) !important;
  }
}

@mixin table-header() {
  .table__header {
    .mat-sort-header-button {
      text-transform: uppercase;
    }
    .mat-sort-header-arrow {
      color: $tab-title;
    }
  }
}

@mixin wide-disabled-btn-theme() {
  background-color: darken(map-get($secondary-palette, 200), 2%) !important;
  color: $dark !important;
  cursor: not-allowed !important;
}

@mixin wide-accent-btn-theme() {
  width: 14rem;
  height: 2.5rem;
  background-color: darken(map-get($secondary-palette, 900), 2%) !important;
  color: $lightest !important;

  @include outline-button-on-focus(
    darken(map-get($secondary-palette, 900), 2%)
  );

  &--disabled {
    width: 14rem;
    height: 2.5rem;
    @include wide-disabled-btn-theme();
  }
}

@mixin form-field-theme($theme) {
  // $accent: map-get($theme, accent);
  $accent: mat.get-color-config($theme);

  $accent-palette: map.get($accent, 'accent');

  .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: mat.get-color-from-palette($accent-palette, 500);
  }

  .mat-form-field-appearance-outline {
    .mat-form-field-outline {
      border-radius: .3125rem;
    }
  }

  .mat-form-field-infix {
    border-top-width: .25rem;
  }
}

// @mixin dropdown-theme($theme) {
//   $primary: map-get($theme, primary);
//   $black: mat-contrast($primary, A100);

//   .mat-select-placeholder {
//     color: rgba($black, .6);
//   }

//   .dropdown__field {
//     .mat-select-arrow-wrapper {
//       transform: initial !important;
//     }

//     .mat-form-field-infix {
//       border-top-width: .25rem;
//     }
//   }
// }

// @mixin date-picker-theme($theme) {
//   $accent: map-get($theme, accent);
//   $warn: map-get($theme, warn);
//   $primary: map-get($theme, primary);
//   $black: mat-contrast($primary, A100);

//   .mat-calendar-table-header {
//     color: darken(mat-color($accent, 500), 20%);
//   }

//   .datepicker {
//     .mat-form-field-appearance-outline {
//       .mat-datepicker-toggle {
//         color: rgba($black, .6);
//       }

//       &.mat-form-field-invalid.mat-form-field-invalid {
//         .mat-datepicker-toggle {
//           color: mat-color($warn, 500) !important;
//         }
//       }
//     }

//     &__field {
//       .mat-form-field-infix {
//         border-top-width: .25rem;
//       }
//     }
//   }

//   .table__date {
//     .mat-form-field-wrapper {
//       width: 100%;

//       .mat-table {
//         min-width: auto !important;
//         width: 100% !important;
//       }

//       .mat-input-element {
//         white-space: nowrap;
//         overflow: hidden;
//         text-overflow: ellipsis;
//       }
//     }
//   }
// }

@mixin accordion-theme($theme) {
  // $primary: map-get($theme, primary);
  $primary: mat.get-color-config($theme);

  $primary-palette: map.get($primary, 'primary');

  .accordian {
    .mat-expansion-indicator:after {
      color: mat.get-color-from-palette($primary-palette, 700);
      padding: .25rem;
    }

    .mat-content {
      margin-bottom: -.7rem;
    }
  }
}

// @mixin delete-button-theme($theme) {
//   $accent: map-get($theme, accent);
//   $white: mat-contrast($accent, 900);

//   .dates__button__delete,
//   .wbs__button__delete,
//   .item__button__delete,
//   .team__delete {
//     &.mat-mini-fab.mat-accent {
//       color: $white;
//       background-color: darken(mat-color($accent, 900), 2%);
//       width: 1.6rem;
//       height: 1.6rem;

//       @include outline-button-on-focus(darken(mat-color($accent, 900), 2%));

//       .material-icons {
//         font-size: 1.2rem;
//         display: block;
//         line-height: 0;
//       }
//     }
//   }
// }

// @mixin toggle-button-theme($theme) {
//   $primary: map-get($theme, primary);
//   $accent: map-get($theme, accent);

//   .wbs__toggle {
//     mat-button-toggle-group {
//       height: 2rem;

//       button {
//         &:focus {
//           outline: none;
//           box-shadow: inset 0 0 .5rem mat-color($accent, A400);
//         }
//       }

//       .mat-button-toggle-label-content {
//         line-height: 36px;
//       }

//       .mat-button-toggle-checked {
//         background-color: mat-color($accent, A200);
//         color: mat-color($primary, 500);
//       }
//     }
//   }
// }

// @mixin close__button__flat($theme) {
//   $primary: map-get($theme, primary);
//   $accent: map-get($theme, accent);
//   $white: mat-contrast($accent, 900);
//   $black: mat-contrast($primary, A100);
//   .modal__close__button {
//     text-align: right;
//     button {
//       background-color: $white;
//       color: rgba($black, .6);
//       border: none;
//       border-radius: .25rem;

//       mat-icon {
//         margin-top: .4rem;
//       }

//       @include outline-button-on-focus($black);
//     }
//   }
// }

// @mixin flat-secondary-btn-theme($theme) {
//   $accent: map-get($theme, accent);
//   $white: mat-contrast($accent, 900);
//   $black: mat-contrast($accent, A100);

//   .table__button__delete {
//     color: $white;
//     background-color: darken(mat-color($accent, 900), 2%);
//     @include outline-button-on-focus(darken(mat-color($accent, 900), 2%));

//     &.mat-flat-button[disabled] {
//       color: rgba($black, .6);
//     }
//   }

//   .material-icons {
//     font-size: 1.2rem;
//     margin-bottom: .2rem;
//   }
// }

// @mixin date-location-action-button($theme) {
//   $primary: map-get($theme, primary);
//   $accent: map-get($theme, accent);

//   .table__action__button {
//     padding: 0 .2rem !important;
//     min-width: 1.5rem !important;
//     height: 2.2rem;

//     color: darken(mat-color($accent, 900), 2%);

//     :hover {
//       color: mat-color($primary, 500);
//     }
//   }
// }

@mixin laptop {
  @media (max-width: 84.38rem) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 49.2rem) {
    @content;
  }
}

@mixin tabs-resize {
  @media only screen and (min-width: 37.06rem) and (max-width: 47.06rem) {
    @content;
  }
}

// @mixin checkbox($theme) {
//   $accent: map-get($theme, accent);
//   $white: mat-contrast($accent, 900);

//   .table__checkbox,
//   .dates__checkbox {
//     .mat-checkbox-layout {
//       .mat-checkbox-inner-container {
//         background-color: $lightest !important;
//       }
//     }
//   }
// }

// @mixin chips-theme($theme) {
//   $accent: map-get($theme, accent);
//   $white: mat-contrast($accent, 900);

//   .look-up {
//     mat-chip {
//       background-color: darken(map-get($accent, 900), 2%);
//       color: $white;

//       mat-icon {
//         font-size: 1.375rem !important;
//         color: $white !important;
//         opacity: 1 !important;
//       }
//     }
//   }
// }

@mixin outline-button-on-focus($focus-colour) {
  &:focus {
    outline: none;
    box-shadow: 0 0 .25rem .15rem rgba($focus-colour, .3);
  }
}

@mixin download-report-spinner() {
  .download-report{
    .mat-spinner{
      margin-right: .25rem;
      circle{
        stroke: $lightest !important;
      }
    }
  }
}

@mixin inline-input-chips() {
  .look-up__list {
    .mat-form-field-infix {
      padding: .85rem 0;
      .mat-chip {
        margin: -.35rem .35rem -.35rem 0;
      }
    }
  }
}