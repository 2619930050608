@import 'variables';

@font-face {
  font-family: '#{$primary-font-family} Bold';
  font-stretch: normal;
  font-style: normal;
  font-weight: 700;
  src: url('#{$primary-font-path}/#{$primary-font-family}-Bold-Web.eot');
  src: url('#{$primary-font-path}/#{$primary-font-family}-Bold-Web.eot?#iefix')
      format('embedded-opentype'),
    url('#{$primary-font-path}/#{$primary-font-family}-Bold-Web.woff2')
      format('woff2'),
    url('#{$primary-font-path}/#{$primary-font-family}-Bold-Web.woff')
      format('woff');
}

@font-face {
  font-family: '#{$primary-font-family} Semibold';
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  src: url('#{$primary-font-path}/#{$primary-font-family}-Semibold-Web.eot');
  src: url('#{$primary-font-path}/#{$primary-font-family}-Semibold-Web.eot?#iefix')
      format('embedded-opentype'),
    url('#{$primary-font-path}/#{$primary-font-family}-Semibold-Web.woff2')
      format('woff2'),
    url('#{$primary-font-path}/#{$primary-font-family}-Semibold-Web.woff')
      format('woff');
}

@font-face {
  font-family: '#{$primary-font-family} Medium';
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  src: url('#{$primary-font-path}/#{$primary-font-family}-Medium-Web.eot');
  src: url('#{$primary-font-path}/#{$primary-font-family}-Medium-Web.eot?#iefix')
      format('embedded-opentype'),
    url('#{$primary-font-path}/#{$primary-font-family}-Medium-Web.woff2')
      format('woff2'),
    url('#{$primary-font-path}/#{$primary-font-family}-Medium-Web.woff')
      format('woff');
}

@font-face {
  font-family: '#{$primary-font-family} Regular';
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  src: url('#{$primary-font-path}/#{$primary-font-family}-Regular-Web.eot');
  src: url('#{$primary-font-path}/#{$primary-font-family}-Regular-Web.eot?#iefix')
      format('embedded-opentype'),
    url('#{$primary-font-path}/#{$primary-font-family}-Regular-Web.woff2')
      format('woff2'),
    url('#{$primary-font-path}/#{$primary-font-family}-Regular-Web.woff')
      format('woff');
}
