$primary-font-stack: 'Graphik Regular',
arial,
sans-serif;
$primary-font-family: 'Graphik' !default;
$primary-font-path: '/assets/fonts/graphik' !default;

$medium-text: 'Graphik Medium',
arial,
sans-serif;
$bold-text: 'Graphik Bold',
arial,
sans-serif;
$semi-bold-text: 'Graphik Semibold',
arial,
sans-serif;

$success: #22b487;
$failure: #c2255c;
$lightest: #ffffff;
$light: #f4f4fc;
$darkest: #000000;
$dark: #2c2c3e;
$tab-title: #6767a0;
$primary-color: #7500c0;
$accent-color: #79789b;
$secondary-color: darken(#0073d1, 10%);
$form-background-color: #f2f2fc;
$main-background-color: #f0f0f7;
$primary-font-size: 1rem;
$secondary-font-size: .875rem;
